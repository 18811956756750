import axios from 'axios';
import { AxiosError } from 'axios'
import User from '../Models/Interfaces/User';
import Ship from '../Models/Interfaces/Ship';
import Address from '../Models/Interfaces/Address';
import { AuthService } from './AuthService';
import Certificate from '../Models/Interfaces/Certificate';
import ReferenceData from '../Models/Interfaces/ReferenceData';
import ProductSubmit from '../Models/SubmitModels/ProductSubmit';
import LandingSubmit from '../Models/SubmitModels/LandingSubmit';
import UserAPIResponse from '../Models/ResponseModels/UserAPIResponse';
import ReferenceLanding from '../Models/Interfaces/ReferenceLanding';
import ShipsAPIResponse from '../Models/ResponseModels/ShipsAPIResponse';
import CertificateSubmit from '../Models/SubmitModels/CertificateSubmit';
import AddressesAPIResponse from '../Models/ResponseModels/AddressesAPIResponse';
import CertificateAPIResponse from '../Models/ResponseModels/CertificateAPIResponse';
import CertificatesAPIResponse from '../Models/ResponseModels/CertificatesAPIResponse';
import ReferenceDataAPIResponse from '../Models/ResponseModels/ReferenceDataAPIResponse';
import ReferenceLandingsAPIResponse from '../Models/ResponseModels/ReferenceLandingsAPIResponse ';
import LandingEditSubmit from '../Models/SubmitModels/LandingEditSubmit';

const authService = new AuthService();
const API_BASE_URL: string | undefined = process.env.REACT_APP_API_BASE_URL;

const errorHandler = (error: unknown, forbiddenRedirect = true) => {
  const axiosError = error as AxiosError;
  if (axiosError.response?.status === 401) {
    authService.login();
  } else if (axiosError.response?.status === 403) {
    if (forbiddenRedirect) {
      authService.logout();
    }
  }
}

/**
 * Retrieves certificates from the API.
 * @param setLoadingState - A function to set the loading state.
 * @param fromDate - Optional parameter to filter certificates by a specific date.
 * @returns A promise that resolves to an array of Certificate objects.
 */
export async function getCertificates(setLoadingState: (loadingState: boolean) => void, fromDate?: Date, toDate?: Date): Promise<Certificate[]> {
  try {
    const user = await authService.getUser();

    let queryString = '';
    if (fromDate && toDate) {
      queryString = `?fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`;
    }
    
    const response = await axios.get<CertificatesAPIResponse>(`${API_BASE_URL}certificates${queryString}`, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    return response.data.certificates;
  } catch (error) {
    setLoadingState(false);
    errorHandler(error);
    throw error;
  }
}

/**
 * Retrieves a certificate from the API by its ID.
 * @param setLoadingState - A function to set the loading state.
 * @param certificateId - The ID of the certificate to retrieve.
 * @returns A promise that resolves to a Certificate object.
 */
export async function getCertificateById(setLoadingState: (loadingState: boolean) => void, certificateId: number): Promise<Certificate> {
  try {
    setLoadingState(true);
    const user = await authService.getUser();
    const response = await axios.get<CertificateAPIResponse>(`${API_BASE_URL}certificates/` + certificateId, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    setLoadingState(false);
    return response.data.certificate;
  } catch (error) {
    setLoadingState(false);
    errorHandler(error);
    throw error;
  }
}

/**
 * Retrieves a certificate PDF from the API by its ID.
 * @param setLoadingState - A function to set the loading state.
 * @param certificateId - The ID of the certificate to retrieve.
 * @returns A promise that resolves to a Blob object.
 */
export async function getCertificatePdfById(setLoadingState: (loadingState: boolean) => void, certificateId: number): Promise<any> {
  try {
    setLoadingState(true);
    const user = await authService.getUser();
    const response = await axios.get<any>(`${API_BASE_URL}certificates/` + certificateId + '/pdf', {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      },
      responseType: 'blob'
    });
    setLoadingState(false);
    return response.data;
  } catch (error) {
    setLoadingState(false);
    errorHandler(error);
    throw error;
  }
}

/**
 * Retrieves a certificate from the API by its ID.
 * @param setLoadingState - A function to set the loading state.
 * @param certificateId - The ID of the certificate to retrieve.
 * @returns A promise that resolves to a Certificate object.
 */
export async function getCertificateByConfirmedCertificateKey(confirmedCertificateKey?: string): Promise<Certificate> {
  try {
    const response = await axios.get<CertificateAPIResponse>(`${API_BASE_URL}confirmed-certificates/` + confirmedCertificateKey);
    return response.data.certificate;
  } catch (error) {
    throw error;
  }
}

/**
 * Retrieves a certificate PDF from the API by its ID.
 * @param setLoadingState - A function to set the loading state.
 * @param certificateId - The ID of the certificate to retrieve.
 * @returns A promise that resolves to a Blob object.
 */
export async function getCertificatePdfByConfirmedCertificateKey(confirmedCertificateKey?: string): Promise<any> {
  try {
    const response = await axios.get<any>(`${API_BASE_URL}confirmed-certificates/` + confirmedCertificateKey + '/pdf', {
      headers: {},
      responseType: 'blob'
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

/**
 * Creates a new certificate.
 * @param setLoadingState - A function to set the loading state.
 * @param certificateId - The ID of the certificate to retrieve.
 * @returns A promise that resolves to a Blob object.
 */
export async function createCertificate(setLoadingState: (loadingState: boolean) => void, certificate: CertificateSubmit) {
  try {
    setLoadingState(true);
    const user = await authService.getUser();
    const response = await axios.post(`${API_BASE_URL}certificates`, certificate, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    setLoadingState(false);
    return response;
  } catch (error) {
    setLoadingState(false);
    errorHandler(error);
    throw error;
  }
}

/**
 * Edit a certificate by its ID.
 * @param setLoadingState 
 * @param certificate 
 * @param certificateId 
 * @returns 
 */
export async function editCertificate(setLoadingState: (loadingState: boolean) => void, certificate: CertificateSubmit, certificateId?: number) {
  try {
    setLoadingState(true);
    const user = await authService.getUser();
    const response = await axios.put(`${API_BASE_URL}certificates/${certificateId}`, certificate, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    setLoadingState(false);
    return response;
  } catch (error) {
    setLoadingState(false);
    errorHandler(error);
    throw error;
  }
}

/**
 * Add a product to a certificate.
 * @param setLoadingState 
 * @param product 
 * @param certificateId 
 * @returns 
 */
export async function addProduct(setLoadingState: (loadingState: boolean) => void, product: ProductSubmit, certificateId?: number) {
  try {
    setLoadingState(true);
    const user = await authService.getUser();
    const response = await axios.post(`${API_BASE_URL}certificates/${certificateId}/products`, product, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    setLoadingState(false);
    return response;
  } catch (error) {
    setLoadingState(false);
    errorHandler(error);
    throw error;
  }
}

/**
 * Edit a product by its ID.
 * @param setLoadingState 
 * @param product 
 * @param productId 
 * @returns 
 */
export async function editProduct(setLoadingState: (loadingState: boolean) => void, product: ProductSubmit, productId?: number) {
  try {
    setLoadingState(true);
    const user = await authService.getUser();
    const response = await axios.put(`${API_BASE_URL}products/${productId}`, product, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    setLoadingState(false);
    return response;
  } catch (error) {
    setLoadingState(false);
    errorHandler(error);
    throw error;
  }
}

/**
 * Deletes a product by its ID.
 * @param setLoadingState - A function to set the loading state.
 * @param productId - The ID of the product to retrieve.
 * @returns A promise that resolves to a Product object.
 */
export async function deleteProduct(setLoadingState: (loadingState: boolean) => void, productId?: number) {
  try {
    setLoadingState(true);
    const user = await authService.getUser();
    const response = await axios.delete(`${API_BASE_URL}products/${productId}`, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    setLoadingState(false);
    return response;
  } catch (error) {
    setLoadingState(false);
    errorHandler(error);
    throw error;
  }
}

/**
 * Adds a landing to a product by product ID.
 * @param setLoadingState - A function to set the loading state.
 * @param certificateId - The ID of the certificate to retrieve.
 * @returns A promise that resolves to a Certificate object.
 */
export async function addLanding(setLoadingState: (loadingState: boolean) => void, landing: LandingSubmit, productId?: number) {
  try {
    setLoadingState(true);
    const user = await authService.getUser();
    const response = await axios.post(`${API_BASE_URL}products/${productId}/landings`, landing, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    setLoadingState(false);
    return response;
  } catch (error) {
    setLoadingState(false);
    errorHandler(error);
    throw error;
  }
}

/**
 * Edits an already connected landing by connected landing id.
 * @param setLoadingState - A function to set the loading state.
 * @param certificateId - The ID of the certificate to retrieve.
 * @returns A promise that resolves to a Certificate object.
 */
export async function editLanding(setLoadingState: (loadingState: boolean) => void, landing: LandingEditSubmit, productId?: number) {
  try {
    setLoadingState(true);
    const user = await authService.getUser();
    const response = await axios.put(`${API_BASE_URL}products/${productId}/landings`, landing, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    setLoadingState(false);
    return response;
  } catch (error) {
    setLoadingState(false);
    errorHandler(error);
    throw error;
  }
}

/**
 * Delete a landing connection from a product.
 * @param setLoadingState 
 * @param landing 
 * @param landingId 
 * @returns 
 */
export async function deleteLanding(setLoadingState: (loadingState: boolean) => void, landingId?: number) {
  try {
    setLoadingState(true);
    const user = await authService.getUser();
    const response = await axios.delete(`${API_BASE_URL}landings/${landingId}`, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    setLoadingState(false);
    return response;
  } catch (error) {
    setLoadingState(false);
    errorHandler(error);
    throw error;
  }
}

/**
 * Retrieves the current user information from the API.
 * @param setLoadingState - A function to set the loading state.
 * @returns A promise that resolves to the user information.
 * @throws An error if there is an issue retrieving the user information.
 */
export async function getCurrentUser(setLoadingState: (loadingState: boolean) => void): Promise<User> {
  try {
    setLoadingState(true);
    const user = await authService.getUser();
    const response = await axios.get<UserAPIResponse>(`${API_BASE_URL}userinfo`, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    setLoadingState(false);
    return response.data.userInfo;
  } catch (error) {
    setLoadingState(false);
    errorHandler(error, false);
    throw error;
  }
}

/**
 * Retrieves the reference data from the API.
 * @returns A Promise that resolves to the reference data.
 * @throws If an error occurs during the API request.
 */
export async function getReferenceData(): Promise<ReferenceData> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceDataAPIResponse>(`${API_BASE_URL}reference-data`, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    return response.data.referenceData;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

/**
 * Retrieves reference landings based on the provided parameters.
 * @param productId - The ID of the product.
 * @param shipNumber - The registration number of the ship.
 * @param fromDate - The starting date for the reference landings.
 * @returns A promise that resolves to an array of ReferenceLanding objects.
 * @throws If an error occurs during the API request.
 */
export async function getReferenceLandings(productId: number | undefined, shipNumber: string, fromDate: string): Promise<ReferenceLanding[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceLandingsAPIResponse>(`${API_BASE_URL}reference-data/matched-landings?productId=${productId}&shipRegistrationNumber=${shipNumber}&fromDate=${fromDate}`, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    return response.data.matchedReferenceLandings;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

/**
 * Retrieves the connected reference landings for a given product ID.
 * 
 * @param productId - The ID of the product.
 * @returns A promise that resolves to an array of ReferenceLanding objects.
 * @throws If an error occurs during the API request.
 */
export async function getConnectedReferenceLandings(productId: number | undefined): Promise<ReferenceLanding[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceLandingsAPIResponse>(`${API_BASE_URL}reference-data/connected-matched-landings?productId=${productId}`, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    return response.data.matchedReferenceLandings;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

/**
 * Confirms a certificate and enerates certificate pdf.
 * 
 * @param setLoadingState - A function to set the loading state.
 * @param certificateId - The ID of the certificate to confirm.
 * @returns The response from the confirmation request.
 * @throws If an error occurs during the confirmation process.
 */
export async function confirmCertificate(setLoadingState: (loadingState: boolean) => void, certificateId?: number) {
  try {
    setLoadingState(true);
    const user = await authService.getUser();
    const response = await axios.post(`${API_BASE_URL}certificates/${certificateId}/confirm`, {}, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    setLoadingState(false);
    return response;
  } catch (error) {
    setLoadingState(false);
    errorHandler(error);
    throw error;
  }
}


/**
 * Void a certificate by sending a request to the API.
 * 
 * @param setLoadingState - A function to set the loading state.
 * @param certificateId - The ID of the certificate to void.
 * @returns A Promise that resolves to the response from the API.
 * @throws If an error occurs during the API request.
 */
export async function voidCertificate(setLoadingState: (loadingState: boolean) => void, certificateId?: number) {
  try {
    setLoadingState(true);
    const user = await authService.getUser();
    const response = await axios.post(`${API_BASE_URL}certificates/${certificateId}/rectify`, {}, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    setLoadingState(false);
    return response;
  } catch (error) {
    setLoadingState(false);
    errorHandler(error);
    throw error;
  }
}


/**
 * Copies certificate data, creates new certificate.
 * 
 * @param setLoadingState - A function to set the loading state.
 * @param certificateId - The ID of the certificate to copy.
 * @returns A promise that resolves to the response from the API.
 * @throws An error if there was an issue copying the certificate.
 */
export async function copyCertificate(setLoadingState: (loadingState: boolean) => void, certificateId?: number) {
  try {
    setLoadingState(true);
    const user = await authService.getUser();
    const response = await axios.post(`${API_BASE_URL}certificates/${certificateId}/copy`, {}, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    setLoadingState(false);
    return response;
  } catch (error) {
    setLoadingState(false);
    errorHandler(error);
    throw error;
  }
}

/**
 * Retrieves a list of ships based on the provided search term.
 * @param searchTerm - The term used to search for ships.
 * @returns A promise that resolves to an array of Ship objects.
 * @throws If an error occurs during the API request.
 */
export async function getShips(searchTerm: string): Promise<Ship[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ShipsAPIResponse>(`${API_BASE_URL}reference-data/ships?searchTerm=${searchTerm}`, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    return response.data.ships;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

/**
 * Retrieves the receiver addresses from the API.
 * @returns A promise that resolves to an array of Address objects.
 * @throws If an error occurs during the API request.
 */
export async function getReceiverAddresses(): Promise<Address[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<AddressesAPIResponse>(`${API_BASE_URL}reference-data/receiver-addresses`, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    return response.data.addresses;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}

/**
 * Retrieves the exporter addresses from the API.
 * @returns A promise that resolves to an array of Address objects.
 * @throws If an error occurs during the API request.
 */
export async function getExporterAddresses(): Promise<Address[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<AddressesAPIResponse>(`${API_BASE_URL}reference-data/exporter-addresses`, {
      headers: {
        Authorization: user?.access_token ? user?.access_token : ''
      }
    });
    return response.data.addresses;
  } catch (error) {
    errorHandler(error);
    throw error;
  }
}