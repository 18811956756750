import { FC, useEffect, useState } from 'react';
import Header from '../../SharedComponents/Header/Header';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Paper, Typography } from '@mui/material';
import docs from './HelpIframes';

interface HelpProps { }

const Help: FC<HelpProps> = () => {

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const desktopView = windowDimensions.width > 1200;

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', width: '100%' }}>

      {/* Header */}
      <Header desktopView={desktopView} hideUserInfo={true} />

      {/* Main content */}
      <Box sx={{ flexGrow: 1, bgcolor: '#f5f5f5', width: '100%', pb: 4 }}>
        <Container maxWidth="lg" sx={{ pt: 4 }}>
          <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
            {/* Page title */}
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{
                textAlign: 'center',
                mb: 4,
                fontWeight: 'bold',
              }}
            >
              Leiðbeiningar
            </Typography>

            {/* Map through documentation sections */}
            {docs.map((section, sectionIndex) => (
              <Box key={sectionIndex} sx={{ mb: 4 }}>
                {/* Section title */}
                <Typography
                  variant="h5"
                  sx={{
                    mb: 3,
                    fontWeight: 'medium',
                  }}
                >
                  {section.title}
                </Typography>

                {/* Map through items within a section */}
                {section.items.map((item, index) => (
                  <Accordion
                    key={index}
                    sx={{
                      mb: 2,
                      '&:before': { display: 'none' },
                      borderRadius: '8px !important',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                      '&:first-of-type': {
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                      },
                      '&:last-of-type': {
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{
                        backgroundColor: 'rgba(0,0,0,0.03)',
                        borderRadius: '8px',
                        '&:hover': {
                          backgroundColor: 'rgba(0,0,0,0.06)',
                        },
                      }}
                    >
                      {/* Item question */}
                      <Typography variant="h6" sx={{ fontWeight: 500 }}>
                        {item.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 3 }}>
                      {/* Render item answer */}
                      {typeof item.answer === 'string' ? (
                        <Typography>{item.answer}</Typography>
                      ) : (
                        item.answer
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            ))}
          </Paper>
        </Container>
      </Box>
    </Box>
  );
}
export default Help;
