import React, { useState } from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

interface Doc {
  title: string;
  items: DocItem[];
}

interface DocItem {
  question: string;
  answer: string | JSX.Element;
}

const ScribeIframe = ({ src }: { src: string }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const handleLoad = () => setLoading(false);
  const handleError = () => setError(true);

  return (
    <Box position="relative" width="100%" height="500px">
      {loading && !error && (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Typography variant="h6" color="error">Failed to load content</Typography>
        </Box>
      )}
      {!error && (
        <iframe
          src={src}
          width="100%"
          height="500"
          allowFullScreen
          style={{ border: 'none', borderRadius: '8px' }}
          onLoad={handleLoad}
          onError={handleError}
          title="Help documentation content"
        />
      )}
    </Box>
  );
};

const docs: Doc[] = [
  {
    title: "Veiðivottorð",
    items: [
      {
        question: "Hvernig bý ég til nýtt veiðivottorð?",
        answer: (
          <ScribeIframe src="https://scribehow.com/shared/Bua_til_nytt_veidivottord__ZCXcQ1-qRQC1NvJw19mhWQ?referrer=documents" />
        ),
      },
      {
        question: "Hvernig afrita ég veiðivottorð?",
        answer: (
          <ScribeIframe src="https://scribehow.com/shared/Afrita_veidivottord__rgWTM34vSbyWUK-H38bLrw?referrer=documents" />
        ),
      },
      {
        question: "Hvernig staðfesti ég veiðivottorð?",
        answer: (
          <ScribeIframe src="https://scribehow.com/shared/Stadfesta_veidivottord__ojf2qPpfRburGUyvdTxaRQ?referrer=documents" />
        ),
      },
      {
        question: "Hvernig leiðrétti ég staðfest vottorð?",
        answer: (
          <ScribeIframe src="https://scribehow.com/shared/Leidretta_stadfest_vottord__f0BksmIsTwmAeuqb_V2Pjg?referrer=documents" />
        ),
      },
      {
        question: "Hvernig uppfæri ég óstaðfest vottorð",
        answer: (
          <ScribeIframe src="https://scribehow.com/shared/Uppfaera_ostadfest_vottord__iP5YTjrMQQ-VWNsCXgq8mw?referrer=documents" />
        )
      },
      {
        question: "Hvernig stilli ég síur í veiðivottorðalista?",
        answer: (
          <ScribeIframe src="https://scribehow.com/shared/Stilla_siur_i_veidivottordalista__XE3YrLzpRQuTAdv-YzcJWg?referrer=documents" />
        ),
      }
    ],
  },
  {
    title: "Vörur",
    items: [
      {
        question: "Hvernig bæti ég við vöru á veiðivottorð?",
        answer: (
          <ScribeIframe src="https://scribehow.com/shared/Baeta_vid_voru_a_veidivottord__FKGoe2UXQteGDk_WoGMUFQ?referrer=documents" />
        ),
      },
      {
        question: "Hvernig breyti ég vöru?",
        answer: (
          <ScribeIframe src="https://scribehow.com/shared/Breyta_voru__q8c0DWxFTH6Fmw16wJqqhA?referrer=documents" />
        ),
      },
      {
        question: "Hvernig afrita ég vöru?",
        answer: (
          <ScribeIframe src="https://scribehow.com/shared/Afrita_voru__rZxDX1QTRWmk8QP0h0Mu8A?referrer=documents" />
        ),
      },
      {
        question: "Hvernig eyði ég vöru?",
        answer: (
          <ScribeIframe src="https://scribehow.com/shared/Eyda_voru__lwMVyb-nQR22uYipWtRo8Q?referrer=documents" />
        ),
      },
    ],
  },
  {
    title: "Landanatengingar",
    items: [
      {
        question: "Hvernig tengi ég löndun við vöru?",
        answer: (
          <ScribeIframe src="https://scribehow.com/shared/Tengja_londun_vid_voru__QzBFrXHBSqy5guvhIWM5KA?referrer=documents" />
        ),
      },
      {
        question: "Hvernig eyði ég löndunartengingu?",
        answer: (
          <ScribeIframe src="https://scribehow.com/shared/Eyda_londunartengingu__3qkgtxpPSJSeLyzKsQ165A?referrer=documents" />
        )
      },
      {
        question: "Hvernig uppfæri ég leitartímabil landana?",
        answer: (
          <ScribeIframe src="https://scribehow.com/shared/Uppfaera_leitartimabil_landana__WfkdP3QCTraox52vL2DoVw?referrer=documents" />
        ),
      },
      {
        question: "Flýtival landana",
        answer: (
          <ScribeIframe src="https://scribehow.com/shared/Flytival_landana__WAjeYkvYSCWLxKEob4gPPQ?referrer=documents" />
        ),
      },
      {
        question: "Hvernig skoða ég eldri vottorð / stilli mánuð?",
        answer: (
          <ScribeIframe src="https://scribehow.com/shared/Skoda_eldri_vottord__stilla_manud__DM7mMzwiSVyfmNDJqYYd5Q?referrer=documents" />
        ),
      },
    ],
  },
];

export default docs;