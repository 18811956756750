import React, { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Timeline from '@mui/lab/Timeline';
import Button from '@mui/material/Button';
import { GridRowId } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import TimelineDot from '@mui/lab/TimelineDot'
import ApiIcon from '@mui/icons-material/Api';
import GppBadIcon from '@mui/icons-material/GppBad';
import FlightIcon from '@mui/icons-material/Flight';
import GppGoodIcon from '@mui/icons-material/GppGood';
import WarningIcon from '@mui/icons-material/Warning';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import BusinessIcon from '@mui/icons-material/Business'
import TimelineContent from '@mui/lab/TimelineContent';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ContentCopy from '@mui/icons-material/ContentCopy';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { Box, Chip, Modal, Stack, Tooltip } from '@mui/material';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import Product from '../../../../Models/Interfaces/Product';
import Certificate from '../../../../Models/Interfaces/Certificate';
import ActionTypes from '../../../../Constants/ActionTypes';
import WeightUnits from '../../../../Constants/WeightUnits';
import CertificateTypes from '../../../../Constants/CertificateTypes';
import CertificateStatuses from '../../../../Constants/CertificateStatuses';
import CertificateProductInfo from '../CertificateProductInfo/CertificateProductInfo';
import ActionConfirmationModal from '../ActionConfirmationModal/ActionConfirmationModal';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { confirmCertificate, getCertificatePdfById } from '../../../../Services/VeidivottordAPIService';
import { Card, CardData, CardLabel, CertificateHeader, CertificateOverviewWrapper, ChipContainer, ContainerCard, RectificationHistoryTitle, RowContainer, StatusContainer, StyledBox, TitleFont } from './CertificateOverview.styled';

interface CertificateOverviewProps {
  selectedCertificate: Certificate | null;
  selectedProduct: Product | undefined;
  toggleProductModal: (actionType?: string) => void;
  toggleLandingModal: () => void;
  toggleCertificateModal: (actionType?: string, inputToFocus?: string) => void;
  setLoadingState: (loadingState: boolean) => void;
  openSnackbar: (statusCode: number) => void;
  handleProductSelect: (rowId: GridRowId | undefined) => void;
  fetchCertificate: (certificateId: number, onConfirm?: boolean) => Promise<Certificate | undefined>;
  fetchCertificates: () => void;
  modalActionType: string | undefined;
  windowDimensions: { width: number, height: number };
  navigationValue: number;
  setNavigationValue: (navigationValue: number) => void;
}

const CertificateOverview: FC<CertificateOverviewProps> = (props) => {

  const [voidSelectionModalOpen, setVoidSelectionModalOpen] = useState(false);
  const [copySelectionModalOpen, setCopySelectionModalOpen] = useState(false);
  const [rectificationHistoryModalOpen, setRectificationHistoryModalOpen] = useState(false);
  const [confirmButtonTooltip, setConfirmButtonTooltip] = useState('Staðfesta vottorð.');
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);
  const [value, setValue] = useState('1');
  const toggleVoidSelectionModal = () => setVoidSelectionModalOpen(!voidSelectionModalOpen);
  const toggleCopySelectionModal = () => setCopySelectionModalOpen(!copySelectionModalOpen);
  const toggleRectificationHistoryModalOpen = () => setRectificationHistoryModalOpen(!rectificationHistoryModalOpen);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleConfirmCertificate = async () => {
    try {
      const response = await confirmCertificate(props.setLoadingState, props.selectedCertificate?.id);
      const certificate = await props.fetchCertificate(props.selectedCertificate?.id ? props.selectedCertificate?.id : 0, true);
      props.openSnackbar(response.status);
      downloadCertificatePdf(certificate?.number);
    } catch (error: any) {
      props.openSnackbar(error.response?.status);
    }
  };

  const openCertificatePdf = async () => {
    const response = await getCertificatePdfById(props.setLoadingState, props.selectedCertificate?.id ? props.selectedCertificate?.id : 0);
    const pdfBlob = new Blob([response], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, '_blank');
  };

  const downloadCertificatePdf = async (certificateNumber?: string) => {
    const response = await getCertificatePdfById(props.setLoadingState, props.selectedCertificate?.id ? props.selectedCertificate?.id : 0);
    const pdfBlob = new Blob([response], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `catch_certificate_${certificateNumber}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    confirmButtonStatus();
  }, [props.selectedCertificate?.products]);

  const getConnectedLandingCount = (product: Product) => {
    // Count by fishTypeId if certificate is from old system, otherwise count by productId.
    return props?.selectedCertificate?.landings
      ? props?.selectedCertificate?.fromDotNetAPI === false
        ? props?.selectedCertificate?.landings.filter(landing => landing.fishType.id === product?.fishType?.id)?.length
        : props?.selectedCertificate?.landings.filter(landing => landing.productId === product?.id)?.length
      : 0
  }

  const confirmButtonStatus = () => {

    // Calculate weight of all landings and products in KG.
    const lbToKgConversion = 0.45359237;
    const ozToKgConversion = 0.028349523;
    const productWeightUnit = props.selectedCertificate?.products?.length ? props.selectedCertificate?.products[0]?.weightUnit?.id : 0;
    const landingWeightSumKg = props.selectedCertificate?.landings?.length ? props.selectedCertificate?.landings?.reduce((total, landing) => total + (landing?.usedAmount), 0) : 0;

    // For each product, check if the product weight is greater than the weight of connected landings, respective to the weight unit.
    // We also sum up the total weight of all products respective to the weight unit.
    let allProductsWeightSumKg = 0;
    const productsWithGreaterProductWeightThanLandings: string[] = [];
    props.selectedCertificate?.products?.forEach(product => {
      const productWeight = product?.nettoWeight;
      const landingWeight = props.selectedCertificate?.landings.length ? props.selectedCertificate?.landings?.filter(landing => landing.productId === product?.id)?.reduce((total, landing) => total + (landing?.usedAmount), 0) : 0;

      if (productWeightUnit === WeightUnits.G) {
        allProductsWeightSumKg += productWeight / 1000;
        if (productWeight / 1000 > landingWeight) {
          productsWithGreaterProductWeightThanLandings.push(product?.fishType?.description ? product?.fishType?.description : '');
        }
      } else if (productWeightUnit === WeightUnits.KG) {
        allProductsWeightSumKg += productWeight;
        if (productWeight > landingWeight) {
          productsWithGreaterProductWeightThanLandings.push(product?.fishType?.description ? product?.fishType?.description : '');
        };
      } else if (productWeightUnit === WeightUnits.LB) {
        allProductsWeightSumKg += productWeight * lbToKgConversion;
        if (productWeight * lbToKgConversion > landingWeight) {
          productsWithGreaterProductWeightThanLandings.push(product?.fishType?.description ? product?.fishType?.description : '');
        }
      } else if (productWeightUnit === WeightUnits.OZ) {
        allProductsWeightSumKg += productWeight * ozToKgConversion;
        if (productWeight * ozToKgConversion > landingWeight) {
          productsWithGreaterProductWeightThanLandings.push(product?.fishType?.description ? product?.fishType?.description : '');
        }
      }
    });

    if (props.selectedCertificate?.status?.id ? true : false) {
      setConfirmButtonDisabled(true);
    } else if (props.selectedCertificate?.products?.length === 0) {
      setConfirmButtonTooltip('Vottorð verður að innihalda að minnsta kosti eina vöru.');
      setConfirmButtonDisabled(true);
    } else if (props.selectedCertificate?.products?.find(product => getConnectedLandingCount(product) === 0)) {
      setConfirmButtonTooltip('Hver vara þarf a.m.k. eina tengingu við löndun.');
      setConfirmButtonDisabled(true);
    } else if (props.selectedCertificate?.fromDotNetAPI === false) {
      setConfirmButtonTooltip('Ekki er hægt að staðfesta vottorð úr eldra kerfi.');
      setConfirmButtonDisabled(true);
    } else if (productsWithGreaterProductWeightThanLandings.length > 0) {
      let errorString = 'Vigt vöru er meiri en vigt tengdra landana við vöruna.\n\n';
      errorString += productsWithGreaterProductWeightThanLandings.map(productDescription => productDescription).join('\n');
      setConfirmButtonTooltip(errorString);
      setConfirmButtonDisabled(true);
    } else if (allProductsWeightSumKg > landingWeightSumKg) {
      setConfirmButtonTooltip('Þyngd vöru er meiri en vigt allra tengdra landana.');
      setConfirmButtonDisabled(true);
    } else if (props.selectedCertificate?.typeId === null) {
      setConfirmButtonTooltip('Velja verður áfangastað vottorðs.');
      setConfirmButtonDisabled(true);
    } else if (props.selectedCertificate?.landings?.find(landing => landing.isReversed === true)) {
      setConfirmButtonTooltip('Vottorð inniheldur löndunartengingu sem hefur verið breytt eða bakfærð.');
      setConfirmButtonDisabled(true);
    } else if (props.selectedCertificate?.landings?.find(landing => landing.closedLanding === true && landing.usedAmount > landing.landedAmount)) {
      setConfirmButtonTooltip('Vottorð inniheldur löndunartengingu þar sem notað magn er meira en landað magn.');
      setConfirmButtonDisabled(true);
    } else {
      setConfirmButtonTooltip('Staðfesta vottorð.');
      setConfirmButtonDisabled(false);
    }
  }

  const isEditable = props.selectedCertificate?.status?.id === CertificateStatuses.UNCONFIRMED && props.selectedCertificate?.fromDotNetAPI === true;
  const desktopView = props.windowDimensions.width > 1200;

  const certificateBaseInfo = (
    <RowContainer>
      <Card
        style={{ cursor: isEditable ? 'pointer' : 'default', width: desktopView ? '' : '100%' }}
        onClick={isEditable ? () => props.toggleCertificateModal(ActionTypes.EDIT_ACTION, 'exporterAddress') : undefined}
      >
        <CardLabel>
          Útflytjandi
          {isEditable && <ModeEditIcon sx={{ fontSize: '1.1em' }} />}
        </CardLabel>
        <CardData style={{ whiteSpace: "pre-line" }}>
          {props.selectedCertificate?.exporterName}
          <br />
          {props.selectedCertificate?.exporterAddress}
          <BusinessIcon />
        </CardData>
      </Card>

      <Card
        style={{ cursor: isEditable ? 'pointer' : 'default', width: desktopView ? '' : '100%' }}
        onClick={isEditable ? () => props.toggleCertificateModal(ActionTypes.EDIT_ACTION, 'receiverName') : undefined}
      >
        <CardLabel>
          <span>
            Móttakandi
            {props.selectedCertificate?.typeId === CertificateTypes.EU &&
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANBAMAAABbflNtAAAAMFBMVEUAM5kiR4MIOJKqmi0tTnoZQogGNpRyeU8LOpFGXWyvnivCqSEBM5dpcVOXjjibkDZC0N4wAAAASUlEQVQI12NgQAUcDTAWl/hFAygztIEjFMLiBAoxTwAz2UBEAph5WoGBgWkPmkkMLB8YGPgcIKYqMjAIKUDNdS+BmctwUABNDwCX4AkbzIMR+gAAAABJRU5ErkJggg=="
                width="20"
                alt="EU"
                style={{ marginLeft: '0.5em' }}
              />
            }
            {props.selectedCertificate?.typeId === CertificateTypes.USA &&
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALBAMAAACNJ7BwAAAAMFBMVEXovcLBS1rQd4LUg43jsLZSTnzirbTPdIDEVWNeXYfVhY9lZIxKRHNWVYHUhI7jsLe2+MkaAAAARUlEQVQI12OIORpzVBAMGKJ3zt6aBgZg5qtVQLCOIfbq3avlYMAwc+bOmQwQANLWAQYMSEAJDhgeG8MAwxcXKHBjEIQDADRVI7/3XjC6AAAAAElFTkSuQmCC"
                width="20"
                alt="USA"
                style={{ marginLeft: '0.5em' }}
              />
            }
            {props.selectedCertificate?.typeId === CertificateTypes.JAPAN &&
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANBAMAAABbflNtAAAAG1BMVEX///+8AC3EH0bJM1fXaIL66+7hj6Ltusa9BzJnmT8QAAAAL0lEQVQI12NgwAnK0mGsYEFBUyjTUVBQBMpUFBQUgjIbBQUlMEWR1CKZgGQuJgAAgvAFYoodbs4AAAAASUVORK5CYII="
                width="20"
                alt="JAPAN"
                style={{ marginLeft: '0.5em' }}
              />
            }
          </span>
          {isEditable && <ModeEditIcon sx={{ fontSize: '1.1em' }} />}
        </CardLabel>
        <CardData style={{ whiteSpace: "pre-line" }}>
          {props?.selectedCertificate?.receiverName}
          <br />
          {props?.selectedCertificate?.receiverAddress}
          <BusinessIcon />
        </CardData>
      </Card>

      <Card
        style={{ cursor: isEditable ? 'pointer' : 'default', width: desktopView ? '' : '100%' }}
        onClick={isEditable ? () => props.toggleCertificateModal(ActionTypes.EDIT_ACTION, 'referenceNumber') : undefined}
      >
        <CardLabel>
          Tilvísunarnúmer
          {isEditable && <ModeEditIcon sx={{ fontSize: '1.1em' }} />}
        </CardLabel>
        <CardData>
          {props?.selectedCertificate?.referenceNumber ? props?.selectedCertificate?.referenceNumber : ' - '} <ContentPasteIcon />
        </CardData>
      </Card>

      {props.selectedCertificate?.transportingShip &&
        <Card
          style={{ cursor: isEditable ? 'pointer' : 'default', width: desktopView ? '' : '100%' }}
          onClick={isEditable ? () => props.toggleCertificateModal(ActionTypes.EDIT_ACTION, 'transportingShip') : undefined}
        >
          <CardLabel>
            Flutningsskip
            {isEditable && <ModeEditIcon sx={{ fontSize: '1.1em' }} />}
          </CardLabel>
          <CardData>
            {props.selectedCertificate?.transportingShip} <DirectionsBoatFilledIcon />
          </CardData>
        </Card>
      }

      {props.selectedCertificate?.containerNumber &&
        <Card
          style={{ cursor: isEditable ? 'pointer' : 'default', width: desktopView ? '' : '100%' }}
          onClick={isEditable ? () => props.toggleCertificateModal(ActionTypes.EDIT_ACTION, 'containerNumber') : undefined}
        >
          <CardLabel>
            Gámanúmer
            {isEditable && <ModeEditIcon sx={{ fontSize: '1.1em' }} />}
          </CardLabel>
          <CardData>
            {props.selectedCertificate?.containerNumber} <LocalShippingIcon />
          </CardData>
        </Card>
      }

      {props.selectedCertificate?.flightNumber &&
        <Card
          style={{ cursor: isEditable ? 'pointer' : 'default', width: desktopView ? '' : '100%' }}
          onClick={isEditable ? () => props.toggleCertificateModal(ActionTypes.EDIT_ACTION, 'flightNumber') : undefined}
        >
          <CardLabel>
            Flugnúmer
            {isEditable && <ModeEditIcon sx={{ fontSize: '1.1em' }} />}
          </CardLabel>
          <CardData>
            {props.selectedCertificate?.flightNumber} <FlightIcon />
          </CardData>
        </Card>
      }
      <Card
        style={{ cursor: isEditable ? 'pointer' : 'default', width: desktopView ? '' : '100%' }}
        onClick={isEditable ? () => props.toggleCertificateModal(ActionTypes.EDIT_ACTION, 'trackingNumber') : undefined}
      >
        <CardLabel>
          Farmbréfsnúmer
          {isEditable && <ModeEditIcon sx={{ fontSize: '1.1em' }} />}
        </CardLabel>
        <CardData>
          {props.selectedCertificate?.trackingNumber ? props.selectedCertificate?.trackingNumber : '-'} <ManageSearchIcon />
        </CardData>
      </Card>
    </RowContainer>
  );

  const certificateProductInfo = (
    <React.Fragment>
      {!desktopView &&
        <span>
          <span style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TitleFont style={{ margin: 0 }}>Vörur</TitleFont>
            <Button
              variant="outlined"
              size="large"
              endIcon={<AddIcon />}
              onClick={() => props.toggleProductModal(ActionTypes.CREATE_ACTION)}
              disabled={(props.selectedCertificate?.status?.id || props.selectedCertificate?.fromDotNetAPI === false || props.selectedCertificate?.id === undefined) ? true : false}
            >
              Bæta við vöru
            </Button>
          </span>


          {(props.selectedCertificate?.products?.length === 0 || props.selectedCertificate?.id === undefined) &&
            <Card style={{ marginTop: '1em' }} onClick={() => props.toggleProductModal(ActionTypes.CREATE_ACTION)}>
              <CardData>
                Engar vörur skráðar.
              </CardData>
            </Card>
          }
        </span>
      }

      <CertificateProductInfo
        selectedCertificate={props.selectedCertificate}
        toggleProductModal={props.toggleProductModal}
        toggleLandingModal={props.toggleLandingModal}
        selectedProduct={props.selectedProduct}
        handleProductSelect={props.handleProductSelect}
        setLoadingState={props.setLoadingState}
        fetchCertificate={props.fetchCertificate}
        openSnackbar={props.openSnackbar}
        windowDimensions={props.windowDimensions}
      />
    </React.Fragment>
  );

  const certificateHeader = (
    <CertificateHeader>
      <StatusContainer style={{ paddingBottom: desktopView ? '' : '1em', flexDirection: desktopView ? 'row' : 'column' }}>
        <span>
          <TitleFont style={{ width: desktopView ? '' : '100%', display: 'flex', justifyContent: 'space-between' }}>
            <span>
              Veiðivottorð {props?.selectedCertificate?.number ? '#' + props?.selectedCertificate?.number : ''}
            </span>
          </TitleFont>

          <ChipContainer>
            <Chip
              icon={props.selectedCertificate?.status?.id === CertificateStatuses.CONFIRMED ? <GppGoodIcon />
                : props.selectedCertificate?.status?.id === CertificateStatuses.VOID ? <GppBadIcon />
                  : <GppMaybeIcon />
              }
              color={props.selectedCertificate?.status?.id === CertificateStatuses.CONFIRMED ? 'success'
                : props.selectedCertificate?.status?.id === CertificateStatuses.VOID ? 'error'
                  : 'default'
              }
              size="small"
              label={props.selectedCertificate?.status?.description}
            />

            <Chip
              icon={<AccessTimeFilledIcon />}
              color={'info'}
              size="small"
              label={dayjs(new Date(props.selectedCertificate?.dateCreated ? props.selectedCertificate?.dateCreated : '')).format('DD/MM/YYYY')}
            />
            {(props?.selectedCertificate?.id !== undefined && props?.selectedCertificate?.fromDotNetAPI === false) &&
              <Tooltip title="Upplýsingar koma úr eldra kerfi. ">
                <Chip
                  icon={<WarningIcon />}
                  color='warning'
                  size='small'
                  label='Frá eldra kerfi' />
              </Tooltip>
            }
            {(props?.selectedCertificate?.id !== undefined && props?.selectedCertificate?.fromExternalClient === true) &&
              <Tooltip title="Upplýsingar koma úr vefþjónustu. ">
                <Chip
                  icon={<ApiIcon />}
                  color='primary'
                  size='small'
                  label='Vefþjónusta' />
              </Tooltip>
            }

            {(props?.selectedCertificate?.rectificationHistory?.length !== undefined && props?.selectedCertificate?.rectificationHistory?.length > 0) &&
              <Tooltip title="Skoða leiðréttingasögu.">
                <Chip
                  onClick={toggleRectificationHistoryModalOpen}
                  icon={<VisibilityIcon />}
                  color='primary'
                  size='small'
                  label='Leiðréttingasaga' />
              </Tooltip>
            }
          </ChipContainer>
        </span>

        <Stack direction="row" sx={{ flexFlow: desktopView ? 'row wrap' : 'column', justifyContent: 'start', gap: '1em', maxHeight: desktopView ? '2em' : '1' }}>
          {/* Óstaðfest vottorð */}
          {(isEditable) &&
            <Tooltip title={<div style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>{confirmButtonTooltip}</div>}>
              <span>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleConfirmCertificate}
                  startIcon={<GppGoodIcon />}
                  disabled={confirmButtonDisabled}
                  style={{ width: desktopView ? '' : '100%' }}
                >
                  Staðfesta
                </Button>
              </span>
            </Tooltip>
          }
          {/* Staðfest vottorð */}
          {(props.selectedCertificate?.status?.id === CertificateStatuses.CONFIRMED && props.selectedCertificate?.fromDotNetAPI === true) &&
            <Tooltip title="Leiðrétta vottorð.">
              <span>
                <Button
                  variant="outlined"
                  color="info"
                  onClick={toggleVoidSelectionModal}
                  startIcon={<ModeEditIcon />}
                  disabled={!!props.selectedCertificate.rectificationHistory.find(certificate => certificate.status.id === CertificateStatuses.UNCONFIRMED)}
                  style={{ width: desktopView ? '' : '100%' }}
                >
                  Leiðrétta
                </Button>
              </span>
            </Tooltip>
          }
          <Tooltip title="Afrita grunnupplýsingar og vörur.">
            <span>
              <Button
                variant="outlined"
                color="info"
                startIcon={<ContentCopy />}
                onClick={toggleCopySelectionModal}
                style={{ width: desktopView ? '' : '100%' }}
              >
                Afrita
              </Button>
            </span>
          </Tooltip>
          {((props.selectedCertificate?.status?.id === CertificateStatuses.CONFIRMED || props.selectedCertificate?.status?.id === CertificateStatuses.VOID) && props?.selectedCertificate?.fromDotNetAPI === true) &&
            <Tooltip title="Skoða PDF">
              <span>
                <Button
                  variant="outlined"
                  color="info"
                  startIcon={<RemoveRedEyeIcon />}
                  onClick={() => openCertificatePdf()}
                  style={{ width: desktopView ? '' : '100%' }}
                >
                  Skoða
                </Button>
              </span>
            </Tooltip>
          }
          {((props.selectedCertificate?.status?.id === CertificateStatuses.CONFIRMED || props.selectedCertificate?.status?.id === CertificateStatuses.VOID) && props?.selectedCertificate?.fromDotNetAPI === true) &&
            <Tooltip title="Hlaða niður PDF">
              <span>
                <Button
                  variant="outlined"
                  color="success"
                  startIcon={<SaveAltIcon />}
                  onClick={() => downloadCertificatePdf(props.selectedCertificate?.number)}
                  style={{ width: desktopView ? '' : '100%' }}
                >
                  Hlaða niður
                </Button>
              </span>
            </Tooltip>
          }
        </Stack>
      </StatusContainer>
    </CertificateHeader>
  );

  return (
    <CertificateOverviewWrapper
      style={{
        width: desktopView ? 'calc(75% - 1em)' : '100%',
        margin: desktopView ? '1em 1em 1em 0em' : '0',
      }}>

      {(desktopView || (props.navigationValue === 1 && !desktopView)) &&
        (
          <ContainerCard
            style={{
              overflow: 'auto',
              minHeight: desktopView ? 'calc(100% - 52px)' : '',
              height: desktopView ? '' : props.navigationValue === 1 ? 'calc(100% - 52px)' : '',
              flex: desktopView ? '1 1 1' : '',
              borderRadius: desktopView ? '0.75em' : '0',
              boxShadow: desktopView ? '' : 'none',
              border: desktopView ? '' : 'none',
              padding: desktopView ? '1em' : '',
            }}>

            {/* Desktop view */}

            {(props.selectedCertificate?.id !== undefined && desktopView) && certificateHeader}
            {(props.selectedCertificate?.id !== undefined && desktopView) && certificateBaseInfo}
            {desktopView && certificateProductInfo}

            {/* Mobile view */}
            {!desktopView &&
              (
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                    <TabList
                      onChange={handleTabChange}
                      sx={{
                        '& .MuiTabs-flexContainer': {
                          justifyContent: 'space-evenly',
                        }
                      }}>
                      <Tab label="Grunnupplýsingar" value="1" />
                      <Tab label="Vöruupplýsingar" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    {props.selectedCertificate?.id !== undefined && certificateHeader}
                    {props.selectedCertificate?.id !== undefined && certificateBaseInfo}
                    {props.selectedCertificate?.id === undefined && "Veldu vottorð til að skoða upplýsingar."}
                  </TabPanel>
                  <TabPanel value="2">
                    {certificateProductInfo}
                  </TabPanel>
                </TabContext>
              )
            }
          </ContainerCard>
        )}

      <ActionConfirmationModal
        actionConfirmationModalOpen={voidSelectionModalOpen || copySelectionModalOpen}
        certificateStatusId={props.selectedCertificate?.status?.id}
        fetchCertificate={props.fetchCertificate}
        fetchCertificates={props.fetchCertificates}
        openSnackbar={props.openSnackbar}
        setLoadingState={props.setLoadingState}
        selectedCertificate={props.selectedCertificate}
        voidSelectionModalOpen={voidSelectionModalOpen}
        copySelectionModalOpen={copySelectionModalOpen}
        toggleVoidSelectionModal={toggleVoidSelectionModal}
        toggleCopySelectionModal={toggleCopySelectionModal}
      />
      <Modal
        open={rectificationHistoryModalOpen}
        onClose={() => toggleRectificationHistoryModalOpen()}
      >
        <StyledBox>
          <RectificationHistoryTitle>
            Leiðréttingasaga
          </RectificationHistoryTitle>
          {rectificationHistoryModalOpen === true &&
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              {props.selectedCertificate?.rectificationHistory?.slice(0).reverse().map((rectifiedCertificate, index) => {
                return (
                  <TimelineItem key={index}>
                    <TimelineSeparator>
                      <TimelineDot />
                      {props.selectedCertificate?.rectificationHistory && index !== props.selectedCertificate?.rectificationHistory?.length - 1 &&
                        <TimelineConnector />
                      }
                    </TimelineSeparator>
                    <TimelineContent>
                      <Card onClick={() => {
                        setRectificationHistoryModalOpen(false);
                        if (rectifiedCertificate?.id) {
                          props.fetchCertificate(rectifiedCertificate?.id);
                        }
                      }} style={{ cursor: 'pointer' }}>
                        <CardData>
                          <span>
                            Veiðivottorð {rectifiedCertificate?.number ? '#' + rectifiedCertificate?.number : ''}
                            <br></br>
                            {dayjs(new Date(rectifiedCertificate?.dateCreated ? rectifiedCertificate?.dateCreated : '')).format('DD/MM/YYYY')}
                          </span>
                          <Chip
                            icon={rectifiedCertificate?.status?.id === CertificateStatuses.CONFIRMED ? <GppGoodIcon />
                              : rectifiedCertificate?.status?.id === CertificateStatuses.VOID ? <GppBadIcon />
                                : <GppMaybeIcon />
                            }
                            color={rectifiedCertificate?.status?.id === CertificateStatuses.CONFIRMED ? 'success'
                              : rectifiedCertificate?.status?.id === CertificateStatuses.VOID ? 'error'
                                : 'default'
                            }
                            size="small"
                            label={rectifiedCertificate?.status?.description} />
                        </CardData>
                      </Card>

                    </TimelineContent>
                  </TimelineItem>);
              })}
            </Timeline>
          }
        </StyledBox>
      </Modal>
    </CertificateOverviewWrapper>
  );
}

export default CertificateOverview;
