import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import GppBadIcon from '@mui/icons-material/GppBad';
import GppGoodIcon from '@mui/icons-material/GppGood';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import BusinessIcon from '@mui/icons-material/Business';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Button, Chip, IconButton, List, Stack, Theme, Tooltip, Typography } from '@mui/material';
import { BaseSingleInputFieldProps, DateValidationError, FieldSection } from '@mui/x-date-pickers/models';
import { DataGrid, GridColDef, GridLogicOperator, GridToolbarContainer, GridToolbarFilterButton, GridToolbarProps, ToolbarPropsOverrides } from '@mui/x-data-grid';
import User from '../../../../Models/Interfaces/User';
import Certificate from '../../../../Models/Interfaces/Certificate';
import ActionTypes from '../../../../Constants/ActionTypes';
import { ButtonWrapper, Card, CardData, CatchCertificateListWrapper, DateFont, ExporterImporterContainer, ListItem } from './CatchCertificateList.styled';
import CertificateStatuses from '../../../../Constants/CertificateStatuses';
import DateShortcuts from '../../../../Constants/DateShortcuts';
import 'dayjs/locale/is';
import { PickersShortcutsItem, PickersShortcutsProps } from '@mui/x-date-pickers';

interface CatchCertificateListProps {
  width: string;
  userInfo: User | null;
  certificatesLoading: boolean;
  certificates: Certificate[] | null;
  selectedCertificate: Certificate | null;
  windowDimensions: { width: number, height: number };
  fetchCertificates: (fromDate?: Date, toDate?: Date) => void;
  fetchCertificate: (certificateId: number) => void;
  toggleCertificateModal: (actionType?: string) => void;
}

const CatchCertificateList: FC<CatchCertificateListProps> = (props) => {

  const [dateFilterFrom, setDateFilterFrom] = React.useState<Dayjs | null>(
    props.userInfo?.isAdmin ? dayjs().startOf('day') : dayjs().subtract(30, 'days').startOf('day')
  );

  const [selectedDateShortcut, setSelectedDateShortcut] = React.useState<string | null>(
    props.userInfo?.isAdmin ? DateShortcuts.TODAY : DateShortcuts.LAST_30_DAYS
  );

  const mobileView = props.windowDimensions.width <= 1200;

  useEffect(() => {
    if (props.selectedCertificate?.dateCreated) {
      const certificateDate = dayjs(props.selectedCertificate.dateCreated);
      const today = dayjs();
      const diffDays = today.diff(certificateDate, 'days');

      let newShortcut: typeof DateShortcuts[keyof typeof DateShortcuts] | null = null;
      if (diffDays === 0) {
        newShortcut = DateShortcuts.TODAY;
      } else if (diffDays <= 7) {
        newShortcut = DateShortcuts.LAST_7_DAYS;
      } else if (diffDays <= 30) {
        newShortcut = DateShortcuts.LAST_30_DAYS;
      } else if (diffDays <= 180) {
        newShortcut = DateShortcuts.LAST_6_MONTHS;
      } else if (diffDays <= 365) {
        newShortcut = DateShortcuts.LAST_12_MONTHS;
      }

      // Ensure we do not set a shortcut that is "less" than the current one
      setSelectedDateShortcut((prevShortcut) => {
        if (!prevShortcut || !newShortcut) {
          return newShortcut;
        }

        const shortcutOrder = [
          DateShortcuts.TODAY,
          DateShortcuts.LAST_7_DAYS,
          DateShortcuts.LAST_30_DAYS,
          DateShortcuts.LAST_6_MONTHS,
          DateShortcuts.LAST_12_MONTHS,
        ];

        return shortcutOrder.indexOf(newShortcut) >= shortcutOrder.indexOf(prevShortcut)
          ? newShortcut
          : prevShortcut;
      });

      if (!newShortcut) {
        setDateFilterFrom(certificateDate.startOf('month'));
      }
    }
  }, [props.selectedCertificate]);

  useEffect(() => {
    if (dateFilterFrom) {
      // Either fetch certificates from the selected date shortcut or the start of the current month.
      const fromDate = selectedDateShortcut
        ? (selectedDateShortcut === DateShortcuts.TODAY
          ? dayjs()
          : selectedDateShortcut === DateShortcuts.LAST_7_DAYS
            ? dayjs().subtract(7, 'days')
            : selectedDateShortcut === DateShortcuts.LAST_30_DAYS
              ? dayjs().subtract(30, 'days')
              : selectedDateShortcut === DateShortcuts.LAST_6_MONTHS
                ? dayjs().subtract(6, 'months')
                : selectedDateShortcut === DateShortcuts.LAST_12_MONTHS
                  ? dayjs().subtract(12, 'months')
                  : dayjs(dateFilterFrom)
        ).startOf('day').toDate()
        : dayjs(dateFilterFrom).startOf('day').toDate();

      // Either fetch certificates from the selected date shortcut or the end of the current month.
      const toDate = selectedDateShortcut
        ? new Date(dayjs().endOf('day').format('YYYY-MM-DD'))
        : new Date(dateFilterFrom.endOf('month').format('YYYY-MM-DD'));

      props.fetchCertificates(fromDate, toDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilterFrom, selectedDateShortcut]);

  const columns: GridColDef[] = [
    {
      field: 'creator',
      filterable: props.userInfo?.isAdmin ? true : false,
      headerName: 'Notandi',
      type: 'singleSelect',
      valueOptions: () => {
        const certificateCreatorNames: string[] = [];
        props.certificates?.forEach(certificate => {
          if (!certificateCreatorNames.includes(certificate.creator.fullName)) {
            certificateCreatorNames.push(certificate.creator.fullName);
          }
        });
        return certificateCreatorNames.sort();
      },
      flex: 1,
      colSpan: 42,
      valueGetter: (params: User) => {
        return params?.fullName;
      },
      renderCell: (params) => (
        <ListItem style={{ backgroundColor: props?.selectedCertificate?.id === params?.row?.id ? '#c7dff1' : 'white' }}>
          <CardData>
            <ExporterImporterContainer>
              {params?.row?.exporterName ?
                <Stack direction="row" alignItems="center" gap={1}>
                  <BusinessIcon sx={{ fontSize: '15px' }} />
                  <Typography variant="body2">{props.userInfo?.isAdmin ? params?.row?.exporterName + '\n ⇾ ' + params?.row?.receiverName : params?.row?.receiverName}</Typography>
                </Stack>
                :
                ''}
              {params?.row?.referenceNumber ?
                <Stack direction="row" alignItems="center" gap={1}>
                  <ContentPasteIcon sx={{ fontSize: '15px' }} />
                  <Typography variant="body2">{params?.row?.referenceNumber}</Typography>
                </Stack>
                :
                ''}
            </ExporterImporterContainer>
            <div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Tooltip title={params?.row?.status?.id === CertificateStatuses.CONFIRMED ? 'Staðfest' : params?.row?.status?.id === CertificateStatuses.VOID ? 'Ógilt' : 'Óstaðfest'}>
                  <Chip
                    icon={params?.row?.status?.id === CertificateStatuses.CONFIRMED ? <GppGoodIcon />
                      : params?.row?.status?.id === CertificateStatuses.VOID ? <GppBadIcon />
                        : <GppMaybeIcon />
                    }
                    color={params?.row?.status?.id === CertificateStatuses.CONFIRMED ? 'success'
                      : params?.row?.status?.id === CertificateStatuses.VOID ? 'error'
                        : 'default'
                    }
                    size="small"
                    sx={{
                      "&.MuiChip-root .MuiChip-icon": {
                        marginRight: "-12px",
                      }
                    }}
                  />
                </Tooltip>
              </div>
              <DateFont>
                {dayjs(new Date(params?.row?.dateCreated ? params?.row?.dateCreated : '')).format('DD/MM/YYYY')}
              </DateFont>
            </div>
          </CardData>
        </ListItem>
      )
    },
    {
      field: 'number',
      type: 'number',
      filterable: true,
      headerName: 'Númer vottorðs'
    },
    {
      field: 'referenceNumber',
      type: 'string',
      filterable: true,
      headerName: 'Tilvísunarnúmer'
    },
    {
      field: 'transportingShip',
      type: 'singleSelect',
      valueOptions: () => {
        const transportingShips: string[] = [];
        props.certificates?.forEach(certificate => {
          if (!transportingShips.includes(certificate.transportingShip)) {
            transportingShips.push(certificate.transportingShip);
          }
        });
        return transportingShips.sort();;
      },
      filterable: true,
      headerName: 'Flutningsskip'
    },
    {
      field: 'containerNumber',
      type: 'string',
      filterable: true,
      headerName: 'Gámanúmer'
    },
    {
      field: 'flightNumber',
      type: 'singleSelect',
      valueOptions: () => {
        const flightNumbers: string[] = [];
        props.certificates?.forEach(certificate => {
          if (!flightNumbers.includes(certificate.flightNumber)) {
            flightNumbers.push(certificate.flightNumber);
          }
        });
        return flightNumbers.sort();
      },
      filterable: true,
      headerName: 'Flugnúmer'
    },
    {
      field: 'trackingNumber',
      type: 'string',
      filterable: true,
      headerName: 'Farmbréfsnúmer'
    },
    {
      field: 'receiverName',
      type: 'singleSelect',
      valueOptions: () => {
        const receiverNames: string[] = [];
        props.certificates?.forEach(certificate => {
          if (!receiverNames.includes(certificate.receiverName)) {
            receiverNames.push(certificate.receiverName);
          }
        });
        return receiverNames.sort();
      },
      filterable: true,
      headerName: 'Nafn viðtakanda'
    },
    {
      field: 'exporterName',
      type: 'singleSelect',
      valueOptions: () => {
        const exporterNames: string[] = [];
        props.certificates?.forEach(certificate => {
          if (!exporterNames.includes(certificate.exporterName)) {
            exporterNames.push(certificate.exporterName);
          }
        });
        return exporterNames.sort();;
      },
      filterable: true,
      headerName: 'Nafn útflytjanda'
    },
  ];

  interface CustomToolbarProps {
    setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  }

  const CustomToolbarWrapper = (props: GridToolbarProps & ToolbarPropsOverrides) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);
    return <CustomToolbar setFilterButtonEl={setFilterButtonEl} {...props} />;
  };

  interface ButtonFieldProps
    extends UseDateFieldProps<Dayjs, false>,
    BaseSingleInputFieldProps<
      Dayjs | null,
      Dayjs,
      FieldSection,
      false,
      DateValidationError
    > {
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  }

  function ButtonField(props: ButtonFieldProps) {
    const {
      setOpen,
      id,
      disabled,
      InputProps: { ref } = {},
    } = props;

    return (
      <Tooltip title="Stilla tímabil">
        <IconButton
          id={id}
          disabled={disabled}
          ref={ref}
          onClick={() => setOpen?.((prev) => !prev)}
          style={{ gap: '0.25em' }}
        >
          <CalendarMonthIcon />
          <p style={{ fontSize: '12px', margin: 0 }}>
            {selectedDateShortcut ? (
              selectedDateShortcut === DateShortcuts.TODAY ? 'Í dag'
                : selectedDateShortcut === DateShortcuts.LAST_7_DAYS ? 'Síðustu 7 dagar'
                  : selectedDateShortcut === DateShortcuts.LAST_30_DAYS ? 'Síðustu 30 dagar'
                    : selectedDateShortcut === DateShortcuts.LAST_6_MONTHS ? 'Síðustu 6 mánuðir'
                      : selectedDateShortcut === DateShortcuts.LAST_12_MONTHS ? 'Síðustu 12 mánuðir'
                        : ''
            ) : (
              <>{dateFilterFrom?.locale('is').format('MMMM YYYY')}</>
            )}
          </p>
        </IconButton>
      </Tooltip>
    );
  }

  const shortcutsItems: PickersShortcutsItem<Dayjs>[] = [
    {
      label: 'Í dag',
      id: 'today',
      getValue: () => {
        const today = dayjs();
        return today;
      }
    },
    {
      label: 'Síðustu 7 dagar',
      id: 'last7days',
      getValue: () => {
        const date = dayjs().subtract(7, 'day');
        return date;
      }
    },
    {
      label: 'Síðustu 30 dagar',
      id: 'last30days',
      getValue: () => {
        const date = dayjs().subtract(30, 'day');
        return date;
      }
    },
    {
      label: 'Síðustu 6 mánuðir',
      id: 'last6months',
      getValue: () => {
        const date = dayjs().subtract(6, 'month');
        return date;
      }
    },
    {
      label: 'Síðustu 12 mánuðir',
      id: 'last12months',
      getValue: () => {
        const date = dayjs().subtract(12, 'month');
        return date;
      }
    }
  ];

  function CustomRangeShortcuts(props: PickersShortcutsProps<Dayjs | null>) {
    return (
      <Box
        sx={{
          gridRow: 4,
          gridColumn: { xs: 2, sm: 2 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: { xs: 'center', sm: 'flex-start' }, // Align left on desktop
          width: { xs: '100%', sm: 'fit-content' }, // Full width on mobile, fit-content on desktop
        }}
      >
        <List
          dense
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column', // Always column layout
            flexWrap: 'nowrap', // Prevent wrapping
            justifyContent: 'flex-start',
            alignItems: { xs: 'center', sm: 'flex-start' }, // Centered on mobile, left-aligned on desktop
            px: theme.spacing(2),
            gap: theme.spacing(1),
          })}
        >
          {shortcutsItems.map((shortcut) => (
            <Chip
              key={shortcut.id}
              id={shortcut.id}
              label={shortcut.label}
              onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                const button = (event.target as HTMLElement).closest('div');
                if (button) {
                  setSelectedDateShortcut(button.id);
                }
              }}
              sx={{
                margin: '4px',
                width: { xs: 'auto', sm: 'fit-content' }, // Auto width on mobile, shrink to content on desktop
                fontSize: { xs: '0.9rem', sm: '0.875rem' },
                padding: { xs: '8px', sm: '4px 8px' },
                '&.MuiChip-root': {
                  height: { xs: '32px', sm: '28px' },
                },
              }}
              variant={selectedDateShortcut === shortcut.id ? 'filled' : 'outlined'}
              color={selectedDateShortcut === shortcut.id ? 'primary' : 'default'}
            />
          ))}
        </List>
      </Box>

    );
  }

  function ButtonDatePicker(
    props: Omit<DatePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'>
  ) {
    const [open, setOpen] = React.useState(false);

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="is">
        <DatePicker
          views={['year', 'month']} // Restrict selection to year and month
          slots={{ field: ButtonField, shortcuts: CustomRangeShortcuts, ...props.slots }}
          slotProps={{
            field: { setOpen } as any,
            layout: {
              sx: {
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'center', // Centered on mobile
                gap: '8px'
              }
            },
            shortcuts: {
              items: shortcutsItems,
              onClick: (event: React.MouseEvent<HTMLUListElement>) => {
                const targetElement = event.target as HTMLElement;
                const button = targetElement.closest("div");
                if (button) {
                  setSelectedDateShortcut(button.id);
                }
              }
            }
          }}
          {...props}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          label="Dagsetning frá"
          maxDate={dayjs()}
        />
      </LocalizationProvider>
    );
  }

  function CustomToolbar({ setFilterButtonEl }: CustomToolbarProps) {
    return (
      <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <GridToolbarFilterButton ref={setFilterButtonEl} />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
            <ButtonDatePicker
              value={dateFilterFrom}
              onChange={(newValue) => {
                setDateFilterFrom(dayjs(newValue?.format('YYYY-MM-DD') as string).startOf('month'));
                setSelectedDateShortcut(null);
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </GridToolbarContainer>
    );
  }

  return (
    <CatchCertificateListWrapper style={{ width: props.width, height: !mobileView ? '100%' : '' }}>
      <Card
        style={{
          margin: mobileView ? '0' : '',
          padding: mobileView ? '0' : '',
          borderRadius: mobileView ? '0' : '0.75rem',
          height: mobileView ? '100%' : '',
        }}>
        <ButtonWrapper style={{ margin: mobileView ? '1em' : '' }}>
          <h4>Veiðivottorð</h4>
          <Button
            variant="contained"
            endIcon={<AddIcon />}
            onClick={() => props.toggleCertificateModal(ActionTypes.CREATE_ACTION)}
          >
            Nýtt vottorð
          </Button>
        </ButtonWrapper>

        <DataGrid
          rows={props?.certificates ? props.certificates : []}
          columns={columns}
          columnVisibilityModel={{
            trackingNumber: false,
            flightNumber: false,
            containerNumber: false,
            transportingShip: false,
            certificateNumber: false,
            referenceNumber: false,
            exporterName: false,
            exporterAddress: false,
            receiverName: false,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              }
            }
          }}
          pageSizeOptions={[100]}
          sx={{
            "&.MuiDataGrid-root": {
              paddingBottom: mobileView ? '4em' : '',
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "&.MuiDataGrid-root .MuiDataGrid-row:focus-within": {
              backgroundColor: "#d9ebfc"
            },
            "&.MuiDataGrid-root .MuiDataGrid-row.Mui-selected": {
              backgroundColor: "#d9ebfc"
            },
            "&.MuiDataGrid-root .MuiDataGrid-virtualScroller": {
              overflowX: 'hidden',
            },
            "& .MuiDataGrid-cell": {
              padding: 0,
            },
            "& .MuiDataGrid-scrollbar": {
              zIndex: 0
            },
            "& .MuiDataGrid-columnSeparator": {
              display: 'none',
            }
          }}
          columnHeaderHeight={0}
          slots={{
            noRowsOverlay: () => (
              <Stack height="90%" alignItems="center" justifyContent="center">
                {props.certificatesLoading ? 'Sækir veiðivottorð...' : 'Engin veiðivottorð.'}
              </Stack>
            ),
            noResultsOverlay: () => (
              <Stack height="90%" alignItems="center" justifyContent="center">
                {props.certificatesLoading ? 'Leitar...' : 'Engin veiðivottorð fundust.'}
              </Stack>
            ),
            toolbar: CustomToolbarWrapper,
          }}
          onRowClick={(row) => props.fetchCertificate(Number(row.id))}
          disableRowSelectionOnClick
          getRowHeight={() => 'auto'}
          disableColumnMenu
          slotProps={{
            filterPanel: {
              logicOperators: [GridLogicOperator.And],
              columnsSort: 'asc',
              filterFormProps: {
                logicOperatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                },
                columnInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 'auto' },
                },
                operatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 'auto' },
                },
                valueInputProps: {
                  InputComponentProps: {
                    variant: 'outlined',
                    size: 'small',
                    label: ''
                  },
                },
                deleteIconProps: {
                  sx: {
                    '& .MuiSvgIcon-root': { color: '#d32f2f' },
                  },
                },
              },
              sx: {
                '& .MuiDataGrid-filterForm': { p: 2 },
                '& .MuiDataGrid-filterForm:nth-of-type(even)': {
                  backgroundColor: (theme: Theme) =>
                    theme.palette.mode === 'dark' ? '#444' : '#f5f5f5',
                },
                '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
                '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                '& .MuiDataGrid-filterFormValueInput': { width: 200 },
                '.MuiNativeSelect-select option': {
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  fontWeight: 400,
                  fontSize: '1rem',
                  lineHeight: '1.4375em',
                  letterSpacing: '0.00938em',
                  color: 'rgba(0, 0, 0, 0.87)',
                  boxSizing: 'border-box',
                  position: 'relative',
                  cursor: 'text',
                  display: 'inline-flex',
                  alignItems: 'center',
                  borderRadius: '4px',
                },
                maxWidth: "100vw"
              },
            },
          }}
          loading={props.certificatesLoading}
          localeText={{
            toolbarFilters: "Síur",
            toolbarFiltersTooltipHide: "Fela síur",
            toolbarFiltersTooltipShow: "Sýna síur",
            columnHeaderFiltersLabel: "Sía eftir",
            filterOperatorContains: "Inniheldur",
            filterOperatorDoesNotContain: "Inniheldur ekki",
            filterOperatorEquals: "Jafnt og",
            filterOperatorDoesNotEqual: "Ekki jafnt og",
            filterOperatorStartsWith: "Byrjar á",
            filterOperatorEndsWith: "Endar á",
            filterOperatorIs: "Er",
            filterOperatorNot: "Er ekki",
            filterOperatorAfter: "Eftir",
            filterOperatorOnOrAfter: "Á eða eftir",
            filterOperatorBefore: "Fyrir",
            filterOperatorOnOrBefore: "Á eða fyrir",
            filterOperatorIsEmpty: "Er tómt",
            filterOperatorIsNotEmpty: "Er ekki tómt",
            filterValueAny: "(einhver)",
            filterValueTrue: "satt",
            filterValueFalse: "ósatt",
            filterOperatorIsAnyOf: "Er einhver af",
          }}
        />
      </Card>
    </CatchCertificateListWrapper>
  );
}

export default CatchCertificateList;
