import React, { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import GppGoodIcon from '@mui/icons-material/GppGood';
import { Button, Chip, CircularProgress, Divider } from '@mui/material';
import Header from '../../SharedComponents/Header/Header';
import Certificate from '../../Models/Interfaces/Certificate';
import { CertificateHeader, ConfirmationStatus, ConfirmedCertificatesWrapper } from './ConfirmedCertificates.styled';
import { getCertificateByConfirmedCertificateKey, getCertificatePdfByConfirmedCertificateKey } from '../../Services/VeidivottordAPIService';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs`;

interface ConfirmedCertificatesProps { }

const ConfirmedCertificates: FC<ConfirmedCertificatesProps> = () => {

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [initalWindowDimensions, setInitialWindowDimensions] = useState(getWindowDimensions());
  const [certificatePdf, setCertificatePdfUrl] = useState<string>();
  const [certificateData, setCertificateData] = useState<Certificate>();
  const [scannedCertificateData, setScannedCertificateData] = useState<Certificate>();
  const [error, setError] = useState<string>();
  const [numPages, setNumPages] = useState(0);

  const desktopView = windowDimensions.width > 1200;
  let { confirmedCertificateKey } = useParams();

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    setInitialWindowDimensions(getWindowDimensions());

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchCertificateData();
  }, [confirmedCertificateKey]);

  const fetchCertificateData = async () => {

    // Fetch the certificate data.
    try {
      const certificateData = await getCertificateByConfirmedCertificateKey(confirmedCertificateKey);
      setCertificateData(certificateData);

      if (certificateData.confirmedCertificateKey !== confirmedCertificateKey) {
        const scannedCertificate = certificateData.rectificationHistory?.find(certificate => certificate.confirmedCertificateKey === confirmedCertificateKey);
        setScannedCertificateData(scannedCertificate);
      }

    } catch (error) {
      setError('Certificate can not be verified.');
      return;
    }

    // Fetch the certificate pdf.
    const certificatePdf = await getCertificatePdfByConfirmedCertificateKey(confirmedCertificateKey);
    const pdfBlob = new Blob([certificatePdf], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    setCertificatePdfUrl(pdfUrl);
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  return (
    <React.Fragment>
      <Header
        desktopView={desktopView}
        hideUserInfo={true}
      />
      <ConfirmedCertificatesWrapper>

        {certificatePdf ? (
          <Document
            file={certificatePdf}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            <CertificateHeader>
              <h1 style={{ margin: 0, display: 'flex', gap: '0.5em', lineHeight: '0.95em' }}>
                Certificate #{certificateData?.number}
                <Chip
                  icon={<GppGoodIcon />}
                  color="success"
                  size="medium"
                  sx={{
                    "&.MuiChip-root .MuiChip-icon": {
                      marginRight: "-19px",
                    }
                  }}
                />
              </h1>
            </CertificateHeader>
            
            

            <ConfirmationStatus>
              <h4 style={{ margin: 0, display: 'flex', gap: '0.5em', lineHeight: '0.95em' }}>
                {scannedCertificateData ? 'This is a rectified copy of certificate #' + scannedCertificateData?.number : ''}
                {scannedCertificateData ? <Chip
                  icon={<GppGoodIcon />}
                  color="error"
                  size="small"
                  sx={{
                    "&.MuiChip-root .MuiChip-icon": {
                      marginRight: "-12px",
                    }
                  }}
                /> : ''}
              </h4>
              <Divider />
              <h3 style={{ margin: '0' }}>Verified electronically by the Icelandic Directorate of Fisheries - {certificateData?.dateCreated ? dayjs(new Date(certificateData.dateCreated)).format('DD/MM/YYYY') : ''} </h3>
              <p style={{ margin: '0' }} >More info about Icelandic catch certificates can be found at <a href="https://island.is/veidivottord">https://island.is/veidivottord</a></p>
              <Button sx={{ marginBottom: '1em' }} variant="contained" color="primary" onClick={() => window.open(certificatePdf, '_blank')} >Download</Button>
            </ConfirmationStatus>

            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map(page => <Page key={page} pageNumber={page} renderTextLayer={false} renderAnnotationLayer={false} width={initalWindowDimensions.width <= 1200 ? initalWindowDimensions.width : 1200} canvasBackground='#F8F8F8' />)}
          </Document>
        )
          : error ? error : <CircularProgress />
        }
      </ConfirmedCertificatesWrapper>
    </React.Fragment>
  );
}
export default ConfirmedCertificates;
